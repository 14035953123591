.reminder-table {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 600px;
  width: 100%;

  .ReactVirtualized__Table {
    .ReactVirtualized__Table__headerRow {
      @include separator();
    }

    .ReactVirtualized__Table__headerColumn:first-of-type, .ReactVirtualized__Table__rowColumn:first-of-type {
      flex: 1;
      margin-left: 0;
    }

    .ReactVirtualized__Table__headerColumn {
      @include font-overline3();
      @include no-select();
      text-transform: none;
      
      // Delivers
      &:nth-child(1) {
        text-align: center;
      }

      // Type
      &:nth-child(2) {
        // Illusion of center aligned
        padding-left: 8px;
        text-align: left;
      }
      
      // Info
      &:nth-child(3) {
        text-align: left;
      }

      // Method
      &:nth-child(4) {
        padding-right: 12px;
        text-align: right;
      }
    }

    .ReactVirtualized__Grid {
      &:focus {
        outline: none;
      }
    }

  }
}

@mixin table-row-component() {
  @include separator();
  cursor: pointer;

  &:hover {
    background: $grey-0;
  }

  &.is-selected {
    background: $grey-1;
    border-left: 3px solid $grey-2;
  }
}

.reminder-table-row-component {
  @include table-row-component();
  display: flex;
  align-items: center;
  
  .delivers {
    @include no-select();
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    
    > .date {
      @include font-overline4(500);
      color: $grey-5;
    }
    
    > .time {
      @include font-overline1(400);
      color: $grey-5;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    
    border-radius: 50%;
    background-color: $grey-2;
    color: $grey-5;
  }
  
  .info {
    @include font-subtitle2();
    white-space: nowrap;

    > .title {
      @include font-body1();
      color: $grey-5;
      padding-bottom: 2px;
    }

    > .subtitle {
      @include font-body3();
      color: $grey-4;
      padding-top: 2px;
    }
  }
  
  .delivery-method {
    display: flex;
    justify-content: flex-end;
    padding: 0 12px 0 0;
  }

  &.is-overdue {
    .delivers {
      > .date {
        color: $grey-4;
      }
      
      > .time {
        color: $grey-4;
      }
    }

    .icon {
      color: $grey-4;
    }

    .info {
      > .title {
        color: $grey-4;
      }
  
      > .subtitle {
        color: $grey-3;
      }
    }
  }
}
