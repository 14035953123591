* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif !important;
}

// LEGACY (RM?)

$blue-1: #0a79fb;
$blue-2: #e0e9f6;
$blue-3: #f3f8ff;

$red-0: #783030;
$red-1: #b97373;
$red-2: #f2d3cc;
$red-3: #ffdfd8;

$orange-0: #88592d;
$orange-1: #e4c1a0;
$orange-2: #fedfc3;
$orange-3: #ffeddd;

// END LEGACY

$white: #ffffff;

$grey-0: #fafafa;
$grey-1: #f8f8f8;
$grey-2: #efefef;
$grey-3: #999999;
$grey-4: #555555;
$grey-5: #333333;
$black: #000000;

$light-text: $grey-3;
$dark-text: $grey-5;

// $breakpoint-mobile: 480px;

/// Tag Overrides

a:link {
  color: $blue-1;
}

// Code formatting
pre {
  background: $grey-1;
  border: 1px solid #cacaca;
  color: $grey-4;
  font-family: monospace;
  font-weight: 400;
  padding: 3px 9px;
  width: fit-content;
  border-radius: 4px;
}

/// UI

@mixin no-select() {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

@mixin grid($count, $columnGap: 12px, $rowGap: 12px) {
  display: grid;
  grid-template-columns: repeat($count, 1fr);
  grid-column-gap: $columnGap;
  grid-row-gap: $rowGap;
}

// Sass Separator
@mixin separator() {
  border-bottom: 0.5px solid #efefef;
}

// Div Separator
.hr {
  @include separator();
}

.hidden {
  display: none;
}

@mixin container() {
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
}

@mixin major-container() {
  max-width: 763px;
  width: 100%;
}

@mixin minor-container() {
  max-width: 445px;
  width: 100%;
}

@mixin content-container-style() {
  @include container-stroke();
  @include container-shadow();
  @include container-radius();
}

@mixin container-stroke() {
  border: 1px solid #e9ecef;
}

@mixin container-shadow() {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
}

@mixin container-radius {
  border-radius: 4px;
}


/// Type

@mixin font-logo() {
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.015em;
  color: $black;
}

@mixin font-h1($weight: 400) {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 42px;
  font-weight: $weight;
  letter-spacing: 0;
  line-height: 48px;
  color: $black;
}

@mixin font-h2($weight: 600) {
  font-size: 24px;
  font-weight: $weight;
  letter-spacing: 0;
  line-height: 33px;
  color: $black;
}

@mixin font-h3($weight: 600) {
  font-size: 24px;
  font-weight: $weight;
  letter-spacing: 0;
  line-height: 28px;
  color: $grey-4;
}

@mixin font-h4($weight: 500) {
  font-size: 20px;
  font-weight: $weight;
  letter-spacing: 0;
  line-height: 24px;
  color: $grey-4;
}

@mixin font-subtitle1($weight: 600) {
  font-size: 20px;
  font-weight: $weight;
  letter-spacing: 0.19em;
  line-height: 32px;
  color: $dark-text;
}

@mixin font-subtitle2($weight: 600) {
  font-size: 18px;
  font-weight: $weight;
  letter-spacing: 0.17em;
  line-height: 28px;
  color: $dark-text;
}

@mixin font-body1($weight: 400) {
  font-size: 16px;
  font-weight: $weight;
  letter-spacing: -0.015em;
  line-height: 21px;
}

@mixin font-body2($weight: 400) {
  font-size: 14px;
  font-weight: $weight;
  letter-spacing: -0.015em;
  line-height: 21px;
}

@mixin font-body3($state: normal, $weight: 400) {
  font-size: 12px;
  font-weight: $weight;
  letter-spacing: 0;
  line-height: 17px;

  @if $state == "inactive" {
    color: $grey-4;
  } @else if $state == "medium" {
    color: $grey-4; // FIXME: rgba(0, 0, 0, 0.5)?
  } @else {
    color: $grey-5;
  }
}

@mixin font-overline1($weight: 500) {
  font-size: 16px;
  font-weight: $weight;
  letter-spacing: -0.015em;
}

@mixin font-overline2($weight: 600) {
  font-size: 16px;
  font-weight: $weight;
}

@mixin font-overline3($weight: 500) {
  font-size: 14px;
  font-weight: $weight;
}

@mixin font-overline4($weight: 400) {
  font-size: 12px;
  font-weight: $weight;
  letter-spacing: -0.015em;
}

@mixin font-button1() {
  font-size: 14px;
  font-weight: 600;
}

@mixin shadow-modal {
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
}

@mixin shadow-panel {
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
}

@mixin shadow-tile {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
}

@mixin shadow-nav {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
}

@mixin plain-input {
  border: none;
  appearance: none;
  padding: 12px;

  &:focus {
    outline: none;
  }
}
