.header-component {
  @include container();
  position: relative;
  padding: 16px 0 16px 0;

  > .content {
    @include container();
    display: flex;
    width: 100%;
    align-items: center;

    > .logo {
      @include font-logo();
    }
  }
}
