.home-container {
  .content-container {
    @include container();
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 24px 0 24px 0;

    > .header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      > .table-header {
        @include major-container;
  
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
  
        padding-bottom: 16px;
  
        > .search {
          max-width: 50%;
        }
  
        > .range {
          margin: 0 12px;
        }
  
        > .today {
          align-self: flex-end;
        }
      }

      > .rhs-header {
        @include minor-container();
        display: flex;
      }
    }

    > .content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      > .reminders {
        @include major-container;
        @include content-container-style();
      }
      
      > .sidebar {
        @include minor-container;
        height: 100%;
        
        padding: 0;
        
        &.is-focused {
          @include content-container-style();
        }
      }
    }
  }

}
