// some antd vars are overridden in config-overrides.js
// full list of overridable vars: https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

body { // wrap in body so this takes precedence over ant styles

  // Button
  .ant-btn {
    @include font-button1();
    height: auto;

    &.ant-btn-primary {
      color: $white;
    }
  }

  // Form Item
  .ant-form-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;

    // Label
    > .ant-form-item-label {
      > label {
        @include font-overline2();
        margin-bottom: 8px;

        &::before {
          display: none
        }
      }
    }

    // Control
    .ant-form-item-control {
      width: 100%;

      > .ant-form-item-explain {
        position: absolute;
        bottom: -24px;
      }
    }
  }

  // Input
  .ant-input {
    @include font-body2();
    padding: 8px 12px;
  }

  .ant-picker {
    @include font-body2();
    
    > .ant-picker-input {
      // padding: 8px 4px;
    }
  }
  
  // Cascade
  .ant-cascader-picker {
    
    > .ant-input {
      padding: 6px 16px;
    }
    
    > .ant-cascader-picker-label {
      padding: 0 16px;
    }
  }

  // Modal
  .ant-modal {
    
    > .ant-modal-content {
      @include shadow-modal();
      border-radius: 0;

      > .ant-modal-close {
        display: none;
      }

      > .ant-modal-header {
        padding: 16px 32px;
        
        > .ant-modal-title {
            @include font-h2();
        }
      }
      
      > .ant-modal-body {
        padding: 24px 32px;
      }

      > .ant-modal-footer {
        padding: 16px 32px;
      }
    }
  }
}
