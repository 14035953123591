.app {
  @include font-body1();
  background: #fff;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .page {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
}
