.reminder-modal-component {

  .ant-picker {
    width: 100%;
    min-height: 44px;
  }

  .ant-input {
    min-height: 44px;
  }

  .recipient-select {
    @include font-body2();
    width: 100%;
    min-height: 44px;

    .recipient-tag {
      display: flex;
      padding: 4px 8px;
      align-items: center;
      
      color: $grey-5;
      
      @include font-overline3();
      background-color: $grey-2;
      border: none;

      .recipient-tag-label {
        padding: 0 8px;
      }

      &.is-invalid {
        background-color: $red-2;
      }
    }
  }

  .hint-note {
    @include font-overline4();
    color: $grey-3;

    padding-bottom: 16px;

    &:last-child {
      margin-top: -20px;
    }
  }
}