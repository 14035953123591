.login-container {
  @include major-container();
  @include content-container-style();

  // Col stack
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;

  // Centered in parent
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > .title {
    @include font-h3();
    padding-bottom: 32px;
  }
}