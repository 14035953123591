.sidebar {
  display: flex;
  flex-direction: column;
  padding: 24px 24px;
  
  > .title {
    @include font-h4(400);
    color: $black;
    padding-bottom: 12px;
    
    min-height: 60px;
  }
  
  .sidebar-item {
    @include font-body2();
    color: $black;
    padding: 12px 0px;

    > .title {
      @include font-overline4();
      @include no-select();
      text-transform: uppercase;
      color: $grey-4;
      
      padding-bottom: 4px;
    }
  }

  > .time-delivery {
    @include grid(2);
  }

  > .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 64px;
  }
}